/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	
}

body, html {
	height: 100%;
	background-color: #daedea;
	/* font-family:  Roboto, "Helvetica Neue", sans-serif; */
    /* font-family: 'Montserrat', sans-serif; */
}

